<template>
  <div>
    <Banner>
      <img style="width:502px;height:416px;bottom:26px" src="@/assets/image/productCenter/orgCert-banner-bg.png" alt="智能印章一体机">
      <template v-slot:text>
        <div class="title">智能印章一体机</div>
        <div class="hint-multi">智能印章一体机是由山东豸信认证服务有限公司自主研发，其核心管理目标是实现企业电子印章的智能化管理和安全性管理，使印章使用的全业务流程可控、可追溯，保证相关数据的安全性和合规性，实现印章管理的可信、可靠、安全。</div>
        <el-button @click="$bus.$emit('cgContactFormShow', true)" type="primary">产品咨询</el-button>
      </template>
    </Banner>
    <Functional class="functional" :list="functional" />
    <Features :list="features" />
    <div class="spec">
      <div class="title">产品规格</div>
      <div class="main-box">
        <div class="tab-butns">
          <div @click="spec_active=0" :class="{active:spec_active===0}">
            <svg-icon class="icon" icon-class="fire" />企业版(B300)
          </div>
          <div @click="spec_active=1" :class="{active:spec_active===1}">旗舰版(B600)</div>
        </div>
        <div v-show="spec_active===0" class="info">
          <div>
            <div class="label">硬件配置</div>
            <div class="params">
              <div>
                <div>机架标准</div>
                <div>2U</div>
              </div>
              <div>
                <div>内存</div>
                <div>16G</div>
              </div>
              <div>
                <div>硬盘</div>
                <div>2T</div>
              </div>
              <div>
                <div>加密卡</div>
                <div>高性能加密卡</div>
              </div>
              <div>
                <div>电源</div>
                <div>双电源</div>
              </div>
              <div>
                <div>显示屏</div>
                <div>LCD液晶屏</div>
              </div>
              <div>
                <div>网口</div>
                <div>可扩展电口</div>
              </div>
            </div>
          </div>
          <div>
            <div class="label">运算性能</div>
            <div class="params">
              <div>
                <div>SM2（S/V）</div>
                <div>11000/4400tps</div>
              </div>
              <!-- <div>
                <div>RSA2048（S/V）</div>
                <div>650/2000tps</div>
              </div> -->
              <div>
                <div>SM4</div>
                <div>7000M</div>
              </div>
            </div>
          </div>
          <div>
            <div class="label">软件功能</div>
            <div class="params">
              <div>
                <div>HA</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <!-- <div>
                <div>证书数量</div>
                <div>300</div>
              </div> -->
              <div>
                <div>骑缝签</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>标准签</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>多页签</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>法大大电子合同集成</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>印章管理</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>印模管理</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>监控功能</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>API服务</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="spec_active===1" class="info">
          <div>
            <div class="label">硬件配置</div>
            <div class="params">
              <div>
                <div>机架标准</div>
                <div>2U</div>
              </div>
              <div>
                <div>内存</div>
                <div>32G</div>
              </div>
              <div>
                <div>硬盘</div>
                <div>2T</div>
              </div>
              <div>
                <div>加密卡</div>
                <div>高性能加密卡*2</div>
              </div>
              <div>
                <div>电源</div>
                <div>双电源</div>
              </div>
              <div>
                <div>显示屏</div>
                <div>LCD液晶屏</div>
              </div>
              <div>
                <div>网口</div>
                <div>可扩展电口</div>
              </div>
            </div>
          </div>
          <div>
            <div class="label">运算性能</div>
            <div class="params">
              <div>
                <div>SM2 (S/V)：</div>
                <div>20000/4000tps</div>
              </div>
              <!-- <div>
                <div>RSA2048（S/V）</div>
                <div>1200/4000tps</div>
              </div> -->
              <div>
                <div>SM4</div>
                <div>10000M</div>
              </div>
            </div>
          </div>
          <div>
            <div class="label">软件功能</div>
            <div class="params">
              <div>
                <div>HA</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <!-- <div>
                <div>证书数量</div>
                <div>300</div>
              </div> -->
              <div>
                <div>骑缝签</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>标准签</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>多页签</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>法大大电子合同集成</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>印章管理</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>印模管理</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>监控功能</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
              <div>
                <div>API服务</div>
                <div><img src="@/assets/icons/productCenter/right-icon.png" alt="right-icon"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LearnMore />
  </div>
</template>

<script>
import Features from './components/Features.vue'
import Functional from './components/Functional.vue'
import LearnMore from './components/LearnMore.vue'
export default {
  components: { Features, Functional, LearnMore },
  data () {
    return {
      functional: [
        {
          icon: 'functional-icon1.png',
          title: '证书管理',
          intro:
            '通过数字证书确认网络中传递信息的个人身份，确保个人线上身份同线下身份一致。'
        },
        {
          icon: 'functional-icon6.png',
          title: '签章服务',
          intro: '标准电子签章服务、批量电子签章服务、骑缝电子签章服务。 '
        },
        {
          icon: 'functional-icon7.png',
          title: '模版管理',
          intro: '模板上传、模板填充；利用模板进行签署。'
        },
        {
          icon: 'functional-icon8.png',
          title: '印章管理',
          intro: '印模和印章的制作、停用、启用、注销等。 '
        },
        {
          icon: 'functional-icon9.png',
          title: '审计功能',
          intro: '印章、印模、证书相关日志审计。'
        },
        {
          icon: 'functional-icon10.png',
          title: '云端监控',
          intro: '监控硬件的状态，包括密钥对、内存、在线管理等。'
        },
        {
          icon: 'functional-icon3.png',
          title: 'HA',
          intro: '高可用、支持双机部署。'
        },
        {
          icon: 'functional-icon11.png',
          title: '接口服务',
          intro:
            '包含证书管理、签署管理、印章管理、监控管理、日志管理等功能的接口对外提供。'
        }
      ],
      features: [
        {
          title: '安全',
          intro:
            '企业级印章/证书统一安全存储；软硬件一体化，防侵入；专用网络接口；证书发放需用户确认，安全可靠；使用记录存储于设备内部，可追溯。'
        },
        {
          title: '合规',
          intro:
            '内置国密型号密码卡；私钥驻留加密卡内部，永不出设备；签名算法支持国产密码标准；数字证书由豸信CA直接颁发。'
        },
        {
          title: '扩展',
          intro:
            '标准化API接口搭配SDK，无缝对接各类业务系统；松散耦合、良好的开放性、可标准、动态集成。'
        },
        {
          title: '易交付',
          intro:
            '一体化交付，标准化产品体验提升；标准化运维体系，降低运维成本。'
        }
      ],
      spec_active: 0
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.functional {
  .item {
    width: 284px;
    height: 296px;
    padding: 32px;
  }
}
.spec {
  padding-top: 160px;
  .title {
    font-size: 36px;
    color: #303b50;
    line-height: 48px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 62px;
  }
  .tab-butns {
    margin-bottom: 40px;
    border-bottom: 1px #dee2e8 solid;
    display: flex;
    & > div {
      font-size: 16px;
      color: #303b50;
      line-height: 62px;
      width: 162px;
      text-align: center;
      margin-right: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      margin-bottom: -1px;
      .icon {
        width: 26px;
        height: 26px;
        margin-right: 4px;
      }
      &.active {
        color: $primaryColor;
        border-bottom: 2px solid $primaryColor;
      }
      &:hover {
        color: $primaryColor;
      }
    }
  }
  .info {
    border: 1px solid #dee2e8;
    & > div {
      display: flex;
      &:last-child > div {
        border-bottom: none;
      }
      .label {
        width: 161px;
        border-right: 1px solid #dee2e8;
        background: linear-gradient(180deg, #e9eef6 0%, #ffffff 100%);
        flex-shrink: 0;
        line-height: 129px;
        text-align: center;
        font-size: 16px;
        color: #303b50;
        border-bottom: 1px solid #e9eef6;
      }
      .params {
        width: 100%;
        display: flex;
        align-items: center;
        height: 130px;
        background: linear-gradient(180deg, #f3f7ff 0%, #fefeff 100%);
        border-bottom: 1px solid #dee2e8;
        padding: 0 32px;
        & > div {
          margin-right: 40px;
          color: #303b50;
          line-height: 19px;
          & > div:last-child {
            background: #ffffff;
            line-height: 31px;
            box-shadow: 0px 4px 16px 0px rgba(31, 37, 81, 0.11);
            border-radius: 1px;
            font-size: 16px;
            margin-top: 16px;
            text-align: center;
            padding: 0 16px;
            img {
              height: 18px;
              width: 18px;
              margin: 6px auto;
              vertical-align: top;
            }
          }
        }
      }
    }
  }
}
</style>
